import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import Aos from 'aos';
import 'aos/dist/aos.css';
// Toast alerts
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import AdminRoute from './components/AdminRoute';
import { Footer, Navbar, Sidebar } from './components';
// Pages
import {
  FbPostPage,
  GdprPage,
  HomePage,
  InstagramPostPage,
  LoginPage,
  RegisterPage,
  TncPage,
  TouPage,
} from './pages';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

// FB SDK
const injectFbSDKScript = () => {
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
};

export const useInitFbSDK = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  // Initializes the SDK once the script has been loaded
  // https://developers.facebook.com/docs/javascript/quickstart/#loading
  window.fbAsyncInit = function () {
    window.FB.init({
      // Find your App ID on https://developers.facebook.com/apps/
      appId: '1239358320322409',
      cookie: true,
      xfbml: true,
      version: 'v8.0',
    });

    window.FB.AppEvents.logPageView();
    setIsInitialized(true);
  };

  injectFbSDKScript();

  return isInitialized;
};

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <Router>
      <Navbar />
      <Sidebar />
      <ScrollToTop />
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDir={'column'}
        w={'full'}
        minH={'calc(100vh - 120px)'}
        px={['2rem', null, '6rem']}
      >
        <Routes>
          <Route path={'/'} element={<HomePage />} />
          <Route path={'/logare'} element={<LoginPage />} />
          <Route path={'/inregistrare'} element={<RegisterPage />} />

          <Route path={'/facebook-post'} element={<FbPostPage />} />
          <Route path={'/instagram-post'} element={<InstagramPostPage />} />

          {/* policy */}
          <Route path={'/termeni-conditii'} element={<TncPage />} />
          <Route path={'/termeni-utilizare'} element={<TouPage />} />
          <Route path={'/gdpr'} element={<GdprPage />} />
        </Routes>
      </Box>
      <Footer />
    </Router>
  );
}

export default App;
