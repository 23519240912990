import {
  Box,
  FormControl,
  FormLabel,
  chakra,
  Input,
  Button,
  Select,
  Flex,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import MessageBox from '../components/MessageBox';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, projects: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const FbPostPage = () => {
  const [{ loading, error, pages, groups }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
    pages: [],
    groups: [],
  });
  // fb logic

  // const [fbUserAccessToken, setFbUserAccessToken] = useState();
  // const [fbPageAccessToken, setFbPageAccessToken] = useState();
  // const [postText, setPostText] = useState();
  // const [isPublishing, setIsPublishing] = useState(false);
  // const isFbSDKInitialized = useInitFbSDK();

  // useEffect(() => {
  //   if (isFbSDKInitialized) {
  //     window.FB.getLoginStatus((response) => {
  //       setFbUserAccessToken(response.authResponse?.accessToken);
  //     });
  //     console.log('Is initialized');
  //   }
  // }, [isFbSDKInitialized]);

  // Logs in the current Facebook user
  // const logInToFB = useCallback(() => {
  //   window.FB.login((response) => {
  //     setFbUserAccessToken(response.authResponse.accessToken);
  //   });
  // }, []);

  // Logs out the current Facebook user
  // const logOutOfFB = useCallback(() => {
  //   window.FB.logout(() => {
  //     setFbUserAccessToken(null);
  //     setFbPageAccessToken(null);
  //   });
  // }, []);

  // Checks if the user is logged in to Facebook

  // Fetches an access token for the page
  // useEffect(() => {
  //   if (fbUserAccessToken) {
  //     window.FB.api('/me', function (response) {
  //       setFbPageAccessToken(response.id);
  //     });
  //   }
  // }, [fbUserAccessToken]);

  // Publishes a post on the Facebook page
  // const sendPostToPage = useCallback(() => {
  //   setIsPublishing(true);

  //   window.FB.api(
  //     `/${pages}/feed`,
  //     'POST',
  //     {
  //       message: postText,
  //       access_token: fbPageAccessToken,
  //     },
  //     () => {
  //       setPostText('');
  //       setIsPublishing(false);
  //     }
  //   );
  // }, [pages, postText, fbPageAccessToken]);
  // end of fb logic

  const postHandler = () => {
    console.log('it works');
  };

  return (
    <Box w={'full'} py={'4rem'}>
      <Flex>
        <div
          className="fb-like"
          data-share="true"
          data-width="450"
          data-show-faces="true"
        ></div>
      </Flex>
      <chakra.form
        display={'flex'}
        flexDir={'column'}
        gap={'3rem'}
        onSubmit={postHandler}
      >
        <FormControl isRequired>
          <FormLabel>Alege Paginile: </FormLabel>

          {pages.length !== 0 ? (
            <Select variant={'flushed'}>
              {pages.map((page) => (
                <option key={page.title} value={page.pageCode}>
                  {page.title}
                </option>
              ))}
            </Select>
          ) : (
            <MessageBox status={'error'}>
              Acest cont nu detine pagini pe care posta
            </MessageBox>
          )}
        </FormControl>
        <FormControl>
          <Button bgColor={'secondary'} color={'textLight'} type={'submit'}>
            Postează
          </Button>
        </FormControl>
      </chakra.form>
    </Box>
  );
};

export default FbPostPage;
